











import { Component, Vue } from "vue-property-decorator";
import { format, parseISO } from "date-fns";

import PostPhotos from "@/layouts/components/blog/admin/PostPhotos.vue";

import EntityCrud from "../../entity/EntityCrud.vue";
import blogPostAdminModel from "@/api/blog_post.admin.model";

import { Media } from "@/interfaces/media";

@Component({
	components: { EntityCrud, PostPhotos },
})
export default class BlogPosts extends Vue {
	model: any = blogPostAdminModel;

	title = "Publicações";
	tableColumns = [
		"createdDate",
		"title",
		{
			name: "Date do evento",
			key: "eventDate",
			valueTransform: (date: string) => (date ? format(parseISO(date), "dd/MM") : ""),
		},
		"mainCategory.name",
		"subcategory.name",
		{
			name: "Design editável?",
			key: "isEditable",
			valueTransform: (isEditable: boolean) => (isEditable ? "Sim" : "Não"),
		},
		{
			name: "Arquivos abertos",
			key: "sourceFilesUrl",
			valueTransform: (sourceFilesUrl: string) => (sourceFilesUrl ? "Arquivo anexado" : "Nenhum arquivo"),
		},
		{
			name: "Imagens",
			key: "photos",
			valueTransform: (photos: Media[]) => {
				if (photos.length) {
					return `${photos.length} ${photos.length === 1 ? "imagem" : "imagens"}`;
				}

				return photos.length ? `${photos.length} ` : "Nenhuma imagem";
			},
		},
	];
	filter = {};

	formColumns = [
		"title",
		"eventDate",
		"mainCategory",
		"subcategory",
		"isEditable",
		"content",
		"coverUrl",
		"sourceFilesUrl",
	];
	defaultEntity = {};
}
